import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import element from 'element-plus'
import 'element-plus/dist/index.css'
import { Dialog } from 'vant';
import "vant/lib/index.css";
import '@/assets/css/index.scss'
import 'dayjs/locale/zh-cn' //中文
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
import utils from '@/utils/utils.js'
import SvgIcon from './components/svgIcon/svgIcon.vue';
const app = createApp(App)
app.config.globalProperties.$utils = utils
app.component('SvgIcon', SvgIcon)
app.use(router).use(Dialog).use(store).use(element, { locale }).mount('#app')