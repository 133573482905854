import axios from 'axios';
import qs from 'qs';

export const loginOnline = (params) => {
    // 设置请求头部为 application/x-www-form-urlencoded
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    // 使用qs.stringify将对象转换为URL查询字符串
    return axios.post(`api/8210/user/onLineLogin`, qs.stringify(params), config);
};

// 短信登录
export const loginMobile = params => {
    // 设置请求头部为 application/x-www-form-urlencoded
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    // 使用qs.stringify将对象转换为URL查询字符串
    return axios.post(`api/8210/user/mobileLogin`, qs.stringify(params), config);
};

// 新增线上用户
export const userOnline = (params) => {
    return axios.post(`api/8210/user/insertOnLineUser`, params);
};
//短信发送管理
export const sendOnline = (params) => {
    return axios.post(`api/8100/message/sendMessage`, params);
};
// 获取地区
export const getAreaList = (params) => {
    return axios.get(`api/8100/region/selectRegionBySjbm`, params);
};
export const forgetPassword = (params) => {
    return axios.post(`api/8210/user/selectUserForUpdate`, params);
};

export const alterPassword = (params) => {
    return axios.post(`api/8210/user/updateUser`, params);
};
