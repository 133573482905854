import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
export default createStore({
  plugins: [createPersistedState()],
  state() {
    return {
      userInfo: {
        yhid: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).yhid : '',
        xm: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).xm : '',
        zhid: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).zhid : '',
        zhmc: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).zhmc : ''
      },
      token: sessionStorage.getItem('token') || '',
      yycdid: Number(sessionStorage.getItem('yycdid')) || '',
      is_socket: 0,
      xm: '',
      fellow: '',
      term: '',
    }
  },
  mutations: {
    // 存储token
    setToken(state, token) {
      state.token = token
    },
    // 存储用户信息
    setInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    // 存储应用信息
    setYycdid(state, yycdid) {
      state.yycdid = yycdid
    },
    setSocket(state, status) {
      state.is_socket = status;
      console.log(state.is_socket)
    },
    setXm(state, xm) {
      state.xm = xm;
    },
    setVip(state, fellow) {
      state.fellow = fellow
    },
    setVipTime(state, term) {
      state.term = term
    },
  },
  actions: {
  },
  getters: {}
})