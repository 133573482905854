
const routes = [
  {
      path: '/',
      name: 'teacher',
      component: () => import('@/views/teacher/index.vue'),
      meta: { title: "教师端" },
      children: [
          // 我的班级
          {
              path: '/class',
              name: 'class',
              component: () => import('@/views/teacher/class/index.vue'),
              meta: { title: "我的班级" },
              children: [
                  {
                      path: '/class/myClass',
                      name: 'myClass',
                      meta: { title: "我的班级" },
                      component: () => import('@/views/teacher/class/myClass.vue'),
                  },
                  {
                      path: '/class/classStudent',
                      name: 'classStudent',
                      meta: { title: "班级学生" },
                      component: () => import('@/views/teacher/class/classStudent.vue'),
                  },
              ],
          },
          // 数据中心
          {
              path: '/dataCenter',
              name: 'dataCenterBox',
              component: () => import('@/views/teacher/dataCenter/index.vue'),
              meta: { title: "数据中心" },
              children: [
                  {
                      path: '/dataCenter',
                      name: 'dataCenter',
                      meta: { title: "数据面板" },
                      component: () => import('@/views/teacher/dataCenter/dataCenter.vue'),
                  },
                  {
                      path: '/dataCenter/achievementData',
                      name: 'achievementData',
                      meta: { title: "成绩数据" },
                      component: () => import('@/views/teacher/dataCenter/achievementData.vue'),
                  },
                  {
                      path: '/dataCenter/classGrade',
                      name: 'classGrade',
                      meta: { title: "班级成绩" },
                      component: () => import('@/views/teacher/dataCenter/classGrade.vue'),
                  },
                //   {
                //       path: '/dataCenter/studentGread',
                //       name: 'studentGread',
                //       meta: { title: "学生成绩" },
                //       component: () => import('@/views/teacher/dataCenter/studentGread.vue'),
                //   },
                //   {
                //       path: '/dataCenter/contrastData',
                //       name: 'contrastData',
                //       meta: { title: "对比分析" },
                //       component: () => import('@/views/teacher/dataCenter/contrastData.vue'),
                //   },
              ],
          },
          // 兑换中心
          {
              path: '/exchange',
              name: 'exchange',
              component: () => import('@/views/teacher/exchange/index.vue'),
              meta: { title: "兑换中心" },
              children: [
                  {
                      path: '/exchange',
                      name: 'exchange',
                      meta: { title: "兑换审核" },
                      component: () => import('@/views/teacher/exchange/exchangeReview.vue'),
                  },
                  {
                      path: '/exchange/exchangeProgress',
                      name: 'exchangeProgress',
                      meta: { title: "兑换进度" },
                      component: () => import('@/views/teacher/exchange/exchangeProgress.vue'),
                  },
              ],
          },
         
      ]
  },

]
export default routes