Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

// 获取当前日或者是推迟后退
const getNowTime = function (year_num, month_num, day_num, time) {

    // year_num 年+n
    // month_num 月+n
    // day_num 日+n
    var date = time ? new Date(time) : new Date()
    // var date = new Date()
    date.setFullYear(date.getFullYear() + year_num);
    date.setMonth(date.getMonth() + month_num);
    date.setDate(date.getDate() + day_num);
    const now = date.Format("yyyy-MM-dd")
    return now


}
export default {
    getNowTime,
}