const routers = [
  {
    path: "/",
    name: "student",
    component: () => import("@/views/student/index"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/student/home"),
      },
      {
        path: "mnemonic",
        name: "mnemonic",
        component: () => import("@/views/student/mnemonic/mnemonic"),
      },
      {
        path: "newEnglish",
        name: "newEnglish",
        component: () => import("@/views/student/english/newEnglish"),
      },
      {
        path: "units",
        name: "units",
        component: () => import("@/views/student/english/units"),
      },
      {
        path: "chapter",
        name: "chapter",
        component: () => import("@/views/student/english/chapter"),
      },
      {
        path: "wordlearning",
        name: "wordlearning",
        component: () => import("@/views/student/english/wordlearning"),
      },
      {
        path: "reviewwordlearning",
        name: "reviewwordlearning",
        component: () => import("@/views/student/english/reviewwordlearning"),
      },
      {
        path: "textlearning",
        name: "textlearning",
        component: () => import("@/views/student/english/textlearning"),
      },
      {
        path: "reviewtextlearning",
        name: "reviewtextlearning",
        component: () => import("@/views/student/english/reviewtextlearning"),
      },
      {
        path: "studyFirstStep",
        name: "studyFirstStep",
        component: () => import("@/views/student/english/studyFirstStep"),
      },
      {
        path: "studyTwoStep",
        name: "studyTwoStep",
        component: () => import("@/views/student/english/studyTwoStep"),
      },
      {
        path: "studyThreeStep",
        name: "studyThreeStep",
        component: () => import("@/views/student/english/studyThreeStep"),
      },
      {
        path: "practiceOneStep",
        name: "practiceOneStep",
        component: () => import("@/views/student/english/practiceOneStep"),
      },
      {
        path: "practiceTwoStep",
        name: "practiceTwoStep",
        component: () => import("@/views/student/english/practiceTwoStep"),
      },
      {
        path: "reviewpracticeTwoStep",
        name: "reviewpracticeTwoStep",
        component: () => import("@/views/student/english/reviewpracticeTwoStep"),
      },
      {
        path: "submitTest",
        name: "submitTest",
        component: () => import("@/views/student/english/submitTest"),
      },
      {
        path: "reviewsubmitTest",
        name: "reviewsubmitTest",
        component: () => import("@/views/student/english/reviewsubmitTest"),
      },
      {
        path: "testStep",
        name: "testStep",
        component: () => import("@/views/student/english/testStep"),
      },
      {
        path: "result",
        name: "result",
        component: () => import("@/views/student/english/result"),
      },
      {
        path: "reviewresult",
        name: "reviewresult",
        component: () => import("@/views/student/english/reviewresult"),
      },
      {
        path: "studyReport",
        name: "studyReport",
        component: () => import("@/views/student/studyReport/index"),
      },
      {
        path: "/personalCenter",
        name: "personalCenter",
        component: () => import("@/views/student/personal/personalCenter.vue"),
        meta: { title: "个人中心" },
        children: [
          {
            path: "coins",
            name: "coins",
            meta: { title: "我的金币" },
            component: () => import("@/views/student/personal/coins.vue"),
          },
          {
            path: "test",
            name: "test",
            meta: { title: "我的金币" },
            component: () => import("@/views/student/personal/test.vue"),
          },
          {
            path: "ranking",
            name: "ranking",
            meta: { title: "金币排行榜" },
            component: () => import("@/views/student/personal/ranking.vue"),
          },
          {
            path: 'order',
            name: 'order',
            meta: {title: "我的订单"},
            component: () => import('@/views/student/personal/orderList.vue'),
          },
          // {
          //   path: '/class/classStudent',
          //   name: 'classStudent',
          //   meta: { title: "班级学生" },
          //   component: () => import('@/views/teacher/class/classStudent.vue'),
          // },
        ],
      },
      {
        path:'/setUpPage',
        name:'setUpPage',
        meta:{ title: "设置" },
        component: () => import('@/views/student/setUpPage/setUpPage.vue'),
        children:[
          {
            path:'information',
            name:'information',
            meta:{ title: "基本资料" },
            component:() => import('@/views/student/setUpPage/information.vue')
          },
          {
            path:'opinion',
            name:'opinion',
            meta:{ title: "意见反馈" },
            component:() => import('@/views/student/setUpPage/opinion.vue')
          },
        ]
      },
      {
        path:'/teacherText',
        name:'teacherText',
        meta:{ title: "教学视频课文" },
        component: () => import('@/views/student/teacherVideo/teacherText.vue'),
      },
      {
        path:'/teacherVideo',
        name:'teacherVideo',
        meta:{ title: "教学视频" },
        component: () => import('@/views/student/teacherVideo/teacherVideo.vue'),
      }
    ],
  },
];
export default routers;
