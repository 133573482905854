import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import login from '@/views/login.vue'
import teaRouters from './teacher.js'
import stuRouters from './student.js'
const router = createRouter({
  // hash
  history:createWebHashHistory(),
  // history
  // history:createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: "/parent",
      name: "parent",
      component: () => import('@/views/parent/index'),
    },
    ...stuRouters,
    ...teaRouters
  ]
})
export default router
