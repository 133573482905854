import axios from "axios";
import { ElMessageBox } from 'element-plus'
let isErrorShown = false; // 用于跟踪是否已经显示错误消息
const service = axios.create({
  baseURL: "",
  headers: {
    get: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
    post: {
      "Content-Type": "application/json;charset=utf-8",
    },
  },
  // 跨域请求时是否需要使用凭证
  withCredentials: true,
  //  请求30s超时
  timeout: 30000,
  // 在向服务器发送请求前，序列化请求数据
  transformRequest: [
    function(data) {
      data = JSON.stringify(data);
      return data;
    },
  ],
  // 在传递给 then/catch 前，修改响应数据
  transformResponse: [
    function(data) {
      if (typeof data === "string" && data.startsWith("{")) {
        data = JSON.parse(data);
      }
      return data;
    },
  ],
});
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    // 请求之前添加请求头
    config.headers.token = sessionStorage.getItem('token')
      config.headers.yhid = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).yhid : ''
    return config;
  },
  (error) => {
    // 抛出错误业务代码
    error.data = {};
    error.data.msg = "服务器异常，请联系管理员!";
    return Promise.resolve(error);
  }
);
service.interceptors.response.use(
    response => {
      if (response.resultCode === 200) {
        // 处理登录成功的逻辑
        return response;
      } else if (response.resultCode === -1 || response.resultCode === 401) {
        window.location.href = '/login'; // 重定向到登录页
        throw new Error('Token expired');
      } else {
        // 处理其他情况的逻辑
        return response;
      }
    },
    error => {
      if(error.response.status ===401){
        if (!isErrorShown) {
          isErrorShown = true;
          ElMessageBox.alert(error.response.data.resultDesc, '提示', {
            confirmButtonText: '确定',
            showClose: false,
            type: 'warning',
            callback: action => {
              window.location.href = '/login';
            }
          });
        }
      }
      return Promise.reject(error);
    }
);

// 添加相应拦截器
async function apiAxios(method, url, params, response) {
  try {
    const res = await service({
      method: method,
      url: url,
      data: method === "POST" || method === "PUT" ? params : null,
      params: method === "GET" || method === "DELETE" ? params : null,
    });
    return new Promise((resolve, reject) => {
      if (res.data.resultCode == 200) {
        resolve(res.data);
      } else {
        resolve(res.data)
        // console.log("请求错误");
      }
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

// 添加响应拦截器
export default {
  get: function(url, params, response) {
    return apiAxios("GET", url, params, response);
  },
  post: function(url, params, response) {
    return apiAxios("POST", url, params, response);
  },
  put: function(url, params, response) {
    return apiAxios("PUT", url, params, response);
  },
  delete: function(url, params, response) {
    return apiAxios("DELETE", url, params, response);
  },
};
